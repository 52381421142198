import { GetIsExtensionInstalledInteractor } from './domain/interactors/get-is-extension-installed.interactor'
import { SyncAdsInteractor } from './domain/interactors/sync-ads.interactor'

export interface ExtensionComponent {
  provideGetIsExtensionInstalled: () => GetIsExtensionInstalledInteractor
  provideSyncAds: () => SyncAdsInteractor
}

export class ExtensionDefaultModule implements ExtensionComponent {
  public provideGetIsExtensionInstalled (): GetIsExtensionInstalledInteractor {
    return new GetIsExtensionInstalledInteractor()
  }

  public provideSyncAds (): SyncAdsInteractor {
    return new SyncAdsInteractor()
  }
}
