import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type AdEntity } from '../../data/entities/ad.entity'
import { Ad, AdListing, AdListingAttribute } from '../models/ad'

export class AdEntityToAdMapper implements Mapper<AdEntity, Ad> {
  public map(from: AdEntity): Ad {
    const listing = from.listing
    const listingImgs = listing.image_urls

    return new Ad(
      from.ad_spent,
      from.roas,
      from.tacos,
      from.missing_cogs,
      new AdListing(
        listing.listing_id,
        listingImgs
          ? {
              sm: listingImgs.small,
              md: listingImgs.medium,
              lg: listingImgs.large
            }
          : undefined,
        listing.title,
        listing.type,
        listing.state,
        listing.url
      ),
      from.synced,
      from.break_even_roas,
      new AdListingAttribute(
        from.orders.total,
        from.orders.from_listing,
        from.orders.from_other_listings,
        from.orders.organic
      ),
      new AdListingAttribute(
        from.sales.total,
        from.sales.from_listing,
        from.sales.from_other_listings,
        from.sales.organic
      )
    )
  }
}
