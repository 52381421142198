import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import type { PaginationOffsetLimit } from '@coris-ts/data/pagination'
import { type Ad } from '../models/ad'
import { GetAdsQuery } from '../../data/queries/get-ads.query'
import { type SortDirection } from '../../../../shared/models/sort-direction'
import type { PeriodsState } from '../../../shared/period-state'

export class GetAdsInteractor {
  constructor(
    private readonly getAds: GetInteractor<PaginationOffsetLimit<Ad>>
  ) {
  }

  public async execute (
    shopId: number,
    periods: PeriodsState,
    offset: number,
    limit: number,
    orderBy?: string,
    searchTerm?: string
  ): Promise<PaginationOffsetLimit<Ad>> {
    const sortBy = orderBy?.split(':')[0]
    const sortDirection = orderBy?.split(':')[1] as SortDirection

    return await this.getAds.execute(
      new GetAdsQuery(
        shopId,
        periods.current.from,
        periods.current.to,
        offset,
        limit,
        searchTerm,
        sortBy,
        sortDirection
      )
    )
  }
}
