export const AppRoutes = {
  AccountSettings: () => '/account-settings',
  Advertisement: () => '/advertisement',
  Catalog: () => '/catalog',
  CogsIssues: () => '/cogs-issues',
  Dashboard: () => '/dashboard',
  Education: () => '/education',
  Integrations: () => '/integrations',
  Listing: (id: number) => AppRoutes.ListingPattern().replace(':listingId', id.toString()),
  ListingPattern: () => `${AppRoutes.Listings()}/:listingId`,
  Listings: () => '/listings',
  LoadingAccount: () => '/loading-account',
  NoSubscriptionEducation: () => '/learning',
  Orders: () => '/orders',
  Payment: () => '/subscription',
  Product: (id: number) => AppRoutes.ProductPattern().replace(':productId', id.toString()),
  ProductCogs: (id: number) => AppRoutes.ProductCogsPattern().replace(':productId', id.toString()),
  ProductCogsPattern: () => `${AppRoutes.Catalog()}/:productId/cogs`,
  ProductPattern: () => `${AppRoutes.Products()}/:productId`,
  Products: () => '/products',
  Settings: () => '/settings',
  Subscription: () => `${AppRoutes.AccountSettings()}/subscription`,
  UnsubscribedAccountSettings: () => `${AppRoutes.Payment()}/account-settings`
}
