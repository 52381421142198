export const ApiEndpoint = {
  Me: 'me',
  Ads: 'ads-stats',
  AdsTotals: 'ads-stats-totals',
  AvailableCredit: 'me/purchases/credit-balance',
  Purchases: 'me/purchases',
  Subscription: 'me/subscription/:subscriptionId',
  Purchase: 'me/subscription/:subscriptionId/charge/:priceId',
  PurchasePreview: 'me/subscription/:subscriptionId/charge/:priceId/preview',
  PreviewSubscriptionChange: 'me/subscription/:subscriptionId/preview',
  CancelSubscription: 'me/subscription/:subscriptionId/cancel',
  ReactivateSubscription: 'me/subscription/:subscriptionId/reactivate',
  PaddleOffering: 'paddle/offering',
  CheckoutStatus: 'me/checkout',
  CogsIssues: 'products-cogs/issues',
  EtsyConnect: 'etsy/connect',
  Shop: 'shop/:shopId',
  Shops: 'shop',
  Analytics: 'reports/global',
  GlobalTimeseries: 'timeseries/global',
  Listings: 'reports/listings',
  Listing: 'reports/listings/:listingId',
  ListingProducts: 'reports/listings/:listingId/products',
  ListingTimeseries: 'timeseries/listings/:listingId',
  Orders: 'orders',
  ProductCogs: 'products/:productId',
  ProductCogsBulkUpdate: 'products-cogs/bulk-update',
  ProductCogsBulkUpdateStatus: 'products-cogs/bulk-update/:statusId',
  ProductCogsHistory: 'products/:productId/cogs/historic',
  ProductCogsPeriod: 'products/:productId/cogs',
  PrintfulConnect: 'printful/connect',
  PrintifyConnect: 'printify/connect',
  Printful: 'shop/:shopId/integrations/printful',
  Printify: 'shop/:shopId/integrations/printify',
  PrintifyShops: '/shop/:shopId/integrations/printify/shops',
  Products: 'reports/products',
  Product: 'reports/products/:productId',
  ProductProducts: '/products/:productId/products',
  ProductListings: 'reports/products/:productId/listings',
  ProductTimeseries: 'timeseries/products/:productId',
  ProductCosts: 'products',
  ReconcileCogs: 'products/:productId/cogs/reconcile'
}
