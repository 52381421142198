import { type Component, Show } from 'solid-js'
import ImageCell from '../shared/components/ImageCell'
import { type ListingReport, ListingStatus } from '../../features/product/domain/models/listing'
import ValueProgressCell from '../shared/components/ValueProgressCell'
import StatusCell from '../shared/components/StatusCell'
import { AppRoutes } from '../../shared/app-routes'
import { A } from '@solidjs/router'
import ViewListingOnEtsyCell from './ViewListingOnEtsyCell'
import NoListing from '../../assets/images/no-listing-sm.svg'

export const ListingStatusColor: Record<ListingStatus, string> = {
  [ListingStatus.Active]: 'green',
  [ListingStatus.Inactive]: 'red',
  [ListingStatus.SoldOut]: 'red',
  [ListingStatus.Expired]: 'gray'
}

export interface ListingTableRowProps {
  readonly listing: ListingReport
  readonly isLoading: () => boolean
}

const ListingTableRow: Component<ListingTableRowProps> = (props) => {
  const listing = props.listing

  return (
    <tr>
      <td class="py-3.5 pl-4 pr-3 text-sm font-medium sm:pl-6 min-w-[88px]">
        <A href={AppRoutes.Listing(listing.id)}>
          <ImageCell imgUrl={listing.isDeleted() ? NoListing : listing.imgUrls.sm} />
        </A>
      </td>
      <td class="tableCell min-w-[240px]">
        <ViewListingOnEtsyCell listing={listing} />
      </td>
      <td class="tableCell min-w-[124px]">
        <Show when={!listing.isDeleted()}>
          <StatusCell color={ListingStatusColor[listing.status]} status={`ls_listing_status_${listing.status}`} />
        </Show>
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={listing.orders} />
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={listing.unitsSold} />
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={listing.netSales} />
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={listing.cogs} warning={listing.missingCogs} />
      </td>
      <td class="tableCell min-w-[148px]">
        <ValueProgressCell kpi={listing.opExpenses} />
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={listing.opProfit} />
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={listing.profitMargin} />
      </td>
      <td class="tableCell min-w-[124px]">
        <ValueProgressCell kpi={listing.roi} />
      </td>
      <td class="tableCell min-w-[180px]">
        <ValueProgressCell kpi={listing.breakEvenRoas} />
      </td>
    </tr>
  )
}

export default ListingTableRow
