import { t } from 'i18next'
import type { Component } from 'solid-js'
import MiniInformationCircle from '../../../assets/heroicons/MiniInformationCircle'
import { type Position } from '../../../shared/helpers/dom.functions'

export interface InfoTooltipProps {
  readonly translationKey: string
  readonly position?: Position
  readonly class?: string
}

const InfoTooltip: Component<InfoTooltipProps> = (props) => {
  return (
    <div
      class={`${props.class} flex flex-col gap-2 border-[1px] border-gray-200 w-fit max-w-[291px] text-gray-900 text-sm absolute bg-white p-4 shadow-lg rounded-lg break-words z-50`}
      style={{
        left: `${props.position?.left ?? 0}px`,
        top: `${props.position?.top ?? 24}px`
      }}>
      <div class="flex gap-2">
        <span class="text-gray-400"><MiniInformationCircle/></span>
        {t(props.translationKey)}
      </div>
    </div>
  )
}

export default InfoTooltip
