import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type AdsTotalsEntity } from '../../data/entities/ads-totals.entity'
import { AdsTotals } from '../models/ads-totals'

export class AdsTotalsEntityToAdsTotalsMapper implements Mapper<AdsTotalsEntity, AdsTotals> {
  public map(from: AdsTotalsEntity): AdsTotals {
    return new AdsTotals(
      from.missing_cogs_ads,
      from.status,
      from.last_end_sync_timestamp
    )
  }
}
