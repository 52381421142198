import { type Component } from 'solid-js'
import SectionCard from './SectionCard'
import StatusCell from '../shared/components/StatusCell'
import MiniCube from '../../assets/heroicons/MiniCube'
import { SubscriptionStatus } from '../../features/payment/domain/models/subscription'
import { useUser } from '../../public/auth/user-provider'
import { SubscriptionStatusColor } from './SubscribedSectionCard'

const LifetimeSectionCard: Component = () => {
  const { currentSubscription } = useUser()

  return (
    <SectionCard icon={<MiniCube/>} title='ls_generic_plan' styleNumColumns='half'>
      <div class="flex flex-col gap-6">
        <div class="flex flex-col gap-6">
          <div class="flex justify-between ">
            <div class="flex flex-col gap-2">
              <span class="font-semibold">{currentSubscription()!.productPrice.name}</span>
            </div>
            <div class="h-6 flex flex-col items-end gap-2">
              <StatusCell status={'ls_subscription_status_active'}
                          color={SubscriptionStatusColor[SubscriptionStatus.Active]}/>
            </div>
          </div>
        </div>
      </div>
    </SectionCard>
  )
}

export default LifetimeSectionCard
