export type AdListingStatus = 'active' | 'inactive' | 'sold_out' | 'draft' | 'expired' | 'edit' | undefined

export class AdListing {
  constructor(
    public readonly id: number,
    public readonly imgUrls: {
      sm?: string
      md?: string
      lg?: string
    } | undefined,
    public readonly title: string | undefined,
    public readonly type: string | undefined,
    public readonly status: AdListingStatus,
    public readonly url: string
  ) {}

  public isDeleted(): boolean {
    return !this.status
  }
}

export class AdListingAttribute {
  constructor(
    public readonly total: number,
    public readonly fromListing: number,
    public readonly fromOtherListings: number,
    public readonly organic: number
  ) {}
}

export class Ad {
  constructor(
    public readonly adSpent: number,
    public readonly roas: number,
    public readonly tacos: number,
    public readonly missingCogs: boolean,
    public readonly listing: AdListing,
    public readonly synced: boolean,
    public readonly breakEvenRoas: number,
    public readonly orders: AdListingAttribute,
    public readonly sales: AdListingAttribute
  ) {}
}
