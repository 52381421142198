import axios from 'axios'
import { formatMinutesToTimezone } from '../../shared/helpers/date.functions'
import { auth0Client, auth0Config } from './auth-provider'

const setAuthTokenCookie = (token: string): void => {
  const cookieName = 'pt_auth_token'
  const maxAge = 60 * 60 * 24 * 365 * 100
  document.cookie = `${cookieName}=${encodeURIComponent(token)}; max-age=${maxAge}; path=/; secure; samesite=strict`
}

axios.interceptors.request.use(async (config) => {
  try {
    const token = await auth0Client.getTokenSilently(auth0Config())

    if (!!token) {
      setAuthTokenCookie(token)
      config.headers = config.headers || {}
      config.headers.Authorization = `Bearer ${token}`
    }

    config.headers['X-Time-Zone'] = formatMinutesToTimezone(new Date().getTimezoneOffset())
  } catch {
    await auth0Client.loginWithRedirect(auth0Config())
  }
  return config
})

export default axios
