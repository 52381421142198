import { type Component } from 'solid-js'
import MiniArrowDownTray from '../../assets/heroicons/MiniArrowDownTray'
import { Trans } from '@mbarzda/solid-i18next'

const NoExtensionWarning: Component = () => {
  const extensionId = import.meta.env.VITE_EXTENSION_ID
  return (
    <div class="flex flex-col sm:flex-row h-full items-center gap-10 mx-auto max-w-[724px]">
      <img src='/images/chrome-extension.png' class="max-h-[425px]"/>
      <div class="flex flex-col gap-6 w-full max-w-[404px]">
        <div class="flex flex-col gap-2">
          <span class="text-2xl font-bold">Download the Extension and Unlock the Full Power of Your Etsy Ads!</span>
          <span class="text-sm">Take control of your Etsy Ads like never before. With our Chrome extension, you can sync your Etsy Ads and instantly see which ads are driving sales and which are eating into your budget.<br/><br/>Get deeper insights, optimize your campaigns, and make data-driven decisions to maximize your profits. Don’t guess; Know exactly how your ads are performing and fine-tune your strategy for real growth!</span>
        </div>
        <div class="flex flex-col gap-2">
          <a class="btn btn--white w-full" href={`https://chromewebstore.google.com/detail/profittree-chrome-extensi/${extensionId}`} target="_blank">
            <MiniArrowDownTray />
            <Trans key="ls_no_extension_install" />
          </a>
          <span class="text-xs text-gray-500">Works with Edge, Safari, Chrome, Brave and any Chromium based browser</span>
        </div>
      </div>
    </div>
  )
}

export default NoExtensionWarning
