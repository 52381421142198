import type { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { type TimeseriesResolution } from '../../../../private/shared/analytics.vm'
import { correctDateToCurrentOffset } from '../../../../shared/helpers/date.functions'
import { type PeriodTime } from '../../../shared/period-state'
import {
  GetProductAnalyticsTimeseriesQuery
} from '../../data/queries/get-product-analytics-timeseries.query'
import { type Timeseries } from '../models/timeseries'

export class GetProductAnalyticsTimeseriesInteractor {
  constructor(
    private readonly getAnalytics: GetInteractor<Timeseries>
  ) {
  }

  public async execute (shopId: number, period: PeriodTime, resolution: TimeseriesResolution, productId?: number): Promise<Timeseries> {
    if (!productId) {
      throw new Error('productId is required')
    }
    const startOfPeriod = correctDateToCurrentOffset(period.from * 1000) / 1000
    const endOfPeriod = correctDateToCurrentOffset(period.to * 1000) / 1000
    return await this.getAnalytics.execute(new GetProductAnalyticsTimeseriesQuery(shopId, productId, startOfPeriod, endOfPeriod, resolution))
  }
}
