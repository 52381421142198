import { type Accessor, type Component, onCleanup, Show } from 'solid-js'
import type { NumericalDataType } from '../../../shared/models/numerical-data-type'
import MiniExclamationTriangle from '../../../assets/heroicons/MiniExclamationTriangle'
import { Trans } from '@mbarzda/solid-i18next'
import { getValueColor } from '../../../shared/helpers/get-value-color'
import { useNumberFormatter } from '../../../shared/helpers/currency-formatter'
import { addComponentToDOM, calculateTooltipPosition, type Position, type Size } from '../../../shared/helpers/dom.functions'
import { useMeasureComponent } from '../functions/use-measure'

export interface ValueCellProps {
  readonly value?: number
  readonly type: NumericalDataType
  readonly warning?: boolean
  readonly colored?: true
  readonly errorTooltip?: Component<{ position?: Position }>
}

const ValueCell: Component<ValueCellProps> = (props) => {
  const { formatNumericValue } = useNumberFormatter()
  let tooltipSize: Accessor<Size> | undefined
  let removeTooltip: () => void = () => {}

  if (props.errorTooltip) {
    tooltipSize = useMeasureComponent(props.errorTooltip({}))
  }

  const showTooltip = (event: MouseEvent): void => {
    if (!tooltipSize?.()) return

    const hoveredEl = event.currentTarget as HTMLElement
    const elementRect = hoveredEl.getBoundingClientRect()

    const position = calculateTooltipPosition(tooltipSize(), elementRect)
    if (!position) return

    removeTooltip = addComponentToDOM(() => props.errorTooltip?.({ position }))
  }

  const hideTooltip = (): void => {
    if (props.errorTooltip) removeTooltip()
  }

  onCleanup(() => {
    hideTooltip()
  })

  return (
    <div class="flex items-center gap-2 text-sm">
      <Show when={props.warning}>
        <span class="text-red-500" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}><MiniExclamationTriangle/></span>
      </Show>
      <Show when={props.value !== undefined} fallback={
        <span class="text-gray-500"><Trans key="ls_generic_n_a"/></span>
      }>
        <span class={`${props.colored ? getValueColor(props.value) : 'text-gray-900'}`}>
          {formatNumericValue(props.type, props.value)}
        </span>
      </Show>
    </div>
  )
}

export default ValueCell
