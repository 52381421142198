import { render } from 'solid-js/web'
import { type JSX } from 'solid-js'

export interface Size {
  width: number
  height: number
}

export interface Position {
  left: number
  top: number
}

export interface PositionAndSize extends Position, Size {}

export const calculateTooltipPosition = (componentSize: Size, origin: PositionAndSize): Position | undefined => {
  const offsetX = 16
  const offsetY = 8

  const rightPosition = origin.left + window.scrollX
  const rightestPosition = window.innerWidth + window.scrollX - componentSize.width - offsetX

  const bottomPosition = origin.top + window.scrollY + origin.height + offsetY
  const topPosition = origin.top + window.scrollY - componentSize.height - offsetY
  const horizontalPOS = Math.min(rightPosition, rightestPosition)

  const positions: Position[] = [
    { top: bottomPosition, left: horizontalPOS },
    { top: topPosition, left: horizontalPOS }
  ]

  const fittingPositions = positions.filter(pos =>
    pos.left >= 0 &&
    pos.top >= 0 &&
    pos.left + componentSize.width <= window.innerWidth + window.scrollX &&
    pos.top + componentSize.height <= window.innerHeight + window.scrollY
  )

  return fittingPositions[0] || undefined
}

export const calculateHorizontalTooltipPosition = (width: number, origin: PositionAndSize): number | undefined => {
  const offsetX = 16

  const rightPosition = origin.left + window.scrollX
  const rightestPosition = window.innerWidth + window.scrollX - width - offsetX

  return Math.min(rightPosition, rightestPosition)
}

export const addComponentToDOM = (element: () => JSX.Element): () => void => {
  const container = document.createElement('div')
  document.body.appendChild(container)

  render(element, container)

  return () => {
    try {
      document.body.removeChild(container)
    } catch {}
  }
}

export const findScrollableParent = (node: HTMLElement): HTMLElement | undefined => {
  let tmpNode: HTMLElement | null = node
  while (tmpNode && tmpNode !== document.body) {
    const overflowX = window.getComputedStyle(tmpNode).overflowX
    if (overflowX === 'auto' || overflowX === 'scroll') return tmpNode
    tmpNode = tmpNode.parentElement
  }

  return undefined
}
