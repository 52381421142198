import { AxiosApiRequestService } from './http/api-request.service'
import { type ApiRequestService } from '@coris-ts/repository/data-source/api-request.service'
import { type AnalyticsComponent, AnalyticsDefaultModule } from './analytics/analytics.component'
import { Cached } from '@coris-ts/helpers/utilities'
import {
  type ProductComponent,
  ProductDefaultModule
} from './product/product.component'
import { type ShopComponent, ShopDefaultModule } from './shop/shop.component'
import axios from '../public/auth/axios.interceptor'
import { type SystemComponent, SystemDefaultModule } from './system/system.component'
import { type AuthComponent, AuthDefaultModule } from './auth/auth.component'
import { type PaymentComponent, PaymentDefaultModule } from './payment/payment.component'
import { type ExtensionComponent, ExtensionDefaultModule } from './extension/extension-component'

export interface MainComponent {
  getAnalyticsComponent: () => AnalyticsComponent
  getProductComponent: () => ProductComponent
  getShopComponent: () => ShopComponent
  getSystemComponent: () => SystemComponent
  getExtensionComponent: () => ExtensionComponent
}

class MainDefaultModule implements MainComponent {
  private readonly apiRequestService: ApiRequestService

  constructor () {
    this.apiRequestService = new AxiosApiRequestService(import.meta.env.VITE_API_URL, axios)
  }

  @Cached()
  public getAnalyticsComponent (): AnalyticsComponent {
    return new AnalyticsDefaultModule(this.apiRequestService)
  }

  @Cached()
  public getAuthComponent(): AuthComponent {
    return new AuthDefaultModule(this.apiRequestService)
  }

  @Cached()
  public getProductComponent (): ProductComponent {
    return new ProductDefaultModule(this.apiRequestService)
  }

  @Cached()
  public getShopComponent(): ShopComponent {
    return new ShopDefaultModule(this.apiRequestService)
  }

  @Cached()
  public getSystemComponent(): SystemComponent {
    return new SystemDefaultModule(this.apiRequestService)
  }

  @Cached()
  public getPaymentComponent(): PaymentComponent {
    return new PaymentDefaultModule(this.apiRequestService)
  }

  @Cached()
  public getExtensionComponent(): ExtensionComponent {
    return new ExtensionDefaultModule()
  }
}

export const MainModule = new MainDefaultModule()
