import { type Component, Show } from 'solid-js'
import { type Listing } from '../../../features/product/domain/models/listing'
import LottieAnimation from '../../shared/components/LottieAnimation'
import logoAnimation from '../../../assets/animations/logo-animated.json'
import { Trans } from '@mbarzda/solid-i18next'
import MiniEtsy from '../../../assets/heroicons/MiniEtsy'
import StatusCell from '../../shared/components/StatusCell'
import { ListingStatusColor } from '../ListingsTableRow'
import NoListing from '../../../assets/images/no-listing-lg.svg'

export interface ListingSummaryBoxProps {
  readonly listing?: Listing
}

const ListingSummaryBox: Component<ListingSummaryBoxProps> = (props) => {
  const listingImage = (listing: Listing): string => listing.isDeleted() ? NoListing : listing.imgUrls.lg

  return (
    <div class="relative bg-blue_gray-200 rounded-2xl p-4 min-h-[148px]">
      <Show when={props.listing} fallback={
        <div class="absolute top-[64px] left-1/2 border-none">
          <LottieAnimation animationData={logoAnimation} width="32px"/>
        </div>
      }>{(listing) => (
        <div class="flex flex-col sm:flex-row gap-4 sm:gap-8">
          <div class="w-full aspect-square relative rounded-lg sm:min-w-[120px] sm:w-[120px] sm:h-[120px] overflow-hidden">
            <img class="h-full w-full object-cover" alt={listing().title} src={listingImage(listing())}/>
          </div>
          <div class="flex flex-col flex-grow gap-4 sm:gap-4">
            <div class="flex flex-col items-start sm:flex-row sm:justify-between sm:items-center">
              <div class="flex gap-2 items-center text-xl font-semibold">
                <Show when={listing().isDeleted()} fallback={
                  <span class="text-gray-900">{listing().title}</span>
                }>
                  <span class="text-gray-500"><Trans key="ls_listing_deleted"/></span>
                </Show>
              </div>
            </div>
            <Show when={!listing().isDeleted()}>
              <StatusCell color={ListingStatusColor[listing().status]} status={`ls_listing_status_${listing().status}`} />
            </Show>
          </div>
          <div>
            <div class="w-full sm:w-fit min-w-[138px]">
              <a href={listing().url} class="btn btn--etsy">
                <MiniEtsy size={4}/>
                <span><Trans key="ls_shared_view_on_etsy"/></span>
              </a>
            </div>
          </div>
        </div>
      )}</Show>
    </div>
  )
}

export default ListingSummaryBox
