import { type Component } from 'solid-js'
import {
  addComponentToDOM,
  calculateTooltipPosition
} from '../../../shared/helpers/dom.functions'
import { useMeasureComponent } from '../functions/use-measure'
import { type NumericalDataType } from '../../../shared/models/numerical-data-type'
import { type AdListingAttribute } from '../../../features/product/domain/models/ad'
import ValueCell from './ValueCell'
import TotalSalesTooltip from '../../advertisement/TotalSalesTooltip'

export interface TotalSalesCellProps {
  readonly type: NumericalDataType
  readonly breakdown: AdListingAttribute
}

const TotalSalesCell: Component<TotalSalesCellProps> = (props) => {
  let removeTooltip: () => void = () => {}
  const tooltipSize = useMeasureComponent(<TotalSalesTooltip breakdown={props.breakdown}/>)

  const showTooltip = (event: MouseEvent): void => {
    const breakdown = props.breakdown
    if (!breakdown || !tooltipSize?.()) return

    const hoveredEl = event.currentTarget as HTMLElement
    const elementRect = hoveredEl.getBoundingClientRect()

    const position = calculateTooltipPosition(tooltipSize(), elementRect)
    if (!position) return

    removeTooltip = addComponentToDOM(() =>
      <TotalSalesTooltip breakdown={breakdown} position={position}/>
    )
  }

  const hideTooltip = (): void => {
    if (!props.breakdown) return
    removeTooltip()
  }

  return (
    <div onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      <ValueCell value={props.breakdown.total} type={props.type} />
    </div>
  )
}

export default TotalSalesCell
