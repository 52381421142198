import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'

export enum ButtonStyle {
  Primary,
  Secondary,
  White,
  Etsy,
  Red
}

export interface ButtonProps {
  readonly style: ButtonStyle
  readonly key: string
  readonly action: () => void
  readonly disabled?: boolean
  readonly icon?: Component<{ size?: number }>
  readonly width?: 'full' | 'fit'
  readonly class?: string
}

const Button: Component<ButtonProps> = (props) => {
  let btnClass = 'btn--primary'
  const width = props.width === 'full' ? 'w-full' : 'min-w-fit'

  switch (props.style) {
    case ButtonStyle.Secondary:
      btnClass = 'btn--secondary'
      break
    case ButtonStyle.White:
      btnClass = 'btn--white'
      break
    case ButtonStyle.Etsy:
      btnClass = 'btn--etsy'
      break
    case ButtonStyle.Red:
      btnClass = 'btn--red'
      break
  }

  return (
    <button onClick={props.action} class={`btn ${btnClass} ${width} ${props.class}`} disabled={props.disabled}>
      {props.icon && <props.icon size={4} />}
      <Trans key={props.key} />
    </button>
  )
}

export default Button
