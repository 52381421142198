import { GetNetworkQuery } from '@coris-ts/repository/query/network.query'
import { type HttpParameters } from '@coris-ts/data/parameter-type'
import { ApiEndpoint } from '../../../../shared/api-endpoint'

export class GetAdsTotalsQuery extends GetNetworkQuery {
  constructor (
    private readonly shopId: number,
    private readonly from: number,
    private readonly to: number
  ) {
    super(ApiEndpoint.AdsTotals)
  }

  public get headers(): HttpParameters {
    return {
      'X-Shop-Id': this.shopId
    }
  }

  public get queryParameters(): HttpParameters {
    return {
      from_date: this.from,
      to_date: this.to
    }
  }
}
