export class SyncAdsInteractor {
  public async execute(extensionId: string | undefined): Promise<boolean> {
    if (!extensionId) {
      throw new Error('No extension ID found in environment variables.')
    }

    return await new Promise((resolve) => {
      try {
        chrome.runtime.sendMessage(
          extensionId,
          { action: 'startSync' },
          (response?: 'true') => {
            if (chrome.runtime.lastError) {
              resolve(false)
            } else if (response === 'true') {
              resolve(true)
            } else {
              resolve(false)
            }
          }
        )
      } catch {
        resolve(false)
      }
    })
  }
}
