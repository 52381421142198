import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { t } from 'i18next'

export interface MissingCogsBannerProps {
  readonly numMissingCogs: number
  readonly action: (open: boolean) => void
}

const MissingCogsBanner: Component<MissingCogsBannerProps> = (props) => {
  return (
    <div class="rounded-lg bg-red-600 text-white flex flex-col sm:flex-row justify-between gap-4 sm:items-center p-4">
      <div class="flex flex-col gap-1">
        <p class="text-xl m-0" innerHTML={t('ls_cogs_banner_missing_line1', { numMissingCogs: props.numMissingCogs })} />
        <p class="text-sm m-0"><Trans key="ls_cogs_banner_missing_line2"/></p>
      </div>
      <button
        onClick={() => { props.action(true) }}
        class="rounded-md bg-white border-gray-200 text-gray-800 text-sm px-3 py-2 hover:bg-gray-200 duration-200 w-full sm:w-fit"
      >
        <Trans key="ls_cogs_fix_now"/>
      </button>
    </div>
  )
}

export default MissingCogsBanner
