import { type Component, onCleanup } from 'solid-js'
import {
  addComponentToDOM,
  calculateTooltipPosition
} from '../../../shared/helpers/dom.functions'
import { useMeasureComponent } from '../functions/use-measure'
import { type NumericalDataType } from '../../../shared/models/numerical-data-type'
import { type AdListingAttribute } from '../../../features/product/domain/models/ad'
import TotalOrdersTooltip from '../../advertisement/TotalOrdersTooltip'
import ValueCell from './ValueCell'

export interface TotalOrdersCellProps {
  readonly type: NumericalDataType
  readonly breakdown: AdListingAttribute
}

const TotalOrdersCell: Component<TotalOrdersCellProps> = (props) => {
  let removeTooltip: () => void = () => {}
  const tooltipSize = useMeasureComponent(<TotalOrdersTooltip breakdown={props.breakdown}/>)

  const showTooltip = (event: MouseEvent): void => {
    const breakdown = props.breakdown
    if (!breakdown || !tooltipSize?.()) return

    const hoveredEl = event.currentTarget as HTMLElement
    const elementRect = hoveredEl.getBoundingClientRect()

    const position = calculateTooltipPosition(tooltipSize(), elementRect)
    if (!position) return

    removeTooltip = addComponentToDOM(() =>
      <TotalOrdersTooltip breakdown={breakdown} position={position}/>
    )
  }

  const hideTooltip = (): void => {
    if (!props.breakdown) return
    removeTooltip()
  }

  onCleanup(() => {
    hideTooltip()
  })

  return (
    <div onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      <ValueCell value={props.breakdown.total} type={props.type}/>
    </div>
  )
}

export default TotalOrdersCell
