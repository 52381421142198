import type { Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { AppRoutes } from '../../shared/app-routes'
import { A } from '@solidjs/router'
import MiniEtsy from '../../assets/heroicons/MiniEtsy'

export interface ViewListingOnEtsyCellProps {
  readonly listing: {
    id: number
    title?: string
    url: string
    isDeleted: () => boolean
  }
}

const ViewListingOnEtsyCell: Component<ViewListingOnEtsyCellProps> = (props) => {
  const listingTitle = props.listing.isDeleted() ? 'ls_listing_deleted' : props.listing.title
  return (
    <>
      <A href={AppRoutes.Listing(props.listing.id)}>
        <div class={`${props.listing.isDeleted() ? 'text-gray-500' : 'text-gray-900'} mb-1 line-clamp-1`}>
          <Trans key={listingTitle ?? ''} />
        </div>
      </A>
      <div class="text-xs text-blue-500">
        <a href={props.listing.url} target="_blank" class="text-gray-500 flex items-center gap-1">
          <MiniEtsy size={3}/><Trans key="ls_shared_view_on_etsy" />
        </a>
      </div>
    </>
  )
}

export default ViewListingOnEtsyCell
