import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import type { PeriodsState } from '../../../shared/period-state'
import { GetAdsTotalsQuery } from '../../data/queries/get-ads-totals.query'
import { type AdsTotals } from '../models/ads-totals'

export class GetAdsTotalsInteractor {
  constructor(
    private readonly getAdsTotals: GetInteractor<AdsTotals>
  ) {
  }

  public async execute (
    shopId: number,
    periods: PeriodsState
  ): Promise<AdsTotals> {
    return await this.getAdsTotals.execute(
      new GetAdsTotalsQuery(
        shopId,
        periods.current.from,
        periods.current.to
      )
    )
  }
}
