import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import Button, { ButtonStyle } from '../shared/components/Button'

export interface NeedAdsSyncBannerProps {
  readonly action: () => void
}

const NeedAdsSyncBanner: Component<NeedAdsSyncBannerProps> = (props) => {
  return (
    <div class="text-white relative flex gap-4 items-center bg-gray-800 justify-between min-h-[80px] flex-col xl:flex-row rounded-lg px-8 xl:px-4 py-4 xl:py-0">
      <img src='/images/bust.svg' class="absolute hidden sm:block sm:w-36 xl:w-[104px] sm:top-4 sm:left-2 xl:top-auto xl:bottom-0 xl:left-4"/>
      <div class="flex flex-col xl:flex-row xl:items-center sm:pl-[136px] xl:pl-[114px] gap-4 w-full">
        <div class="flex flex-col gap-1 justify-center w-full">
          <span class="text-xl m-0"><Trans key="ls_ads_need_sync_banner_line1"/></span>
          <span class="text-sm m-0"><Trans key="ls_ads_need_sync_banner_line2"/></span>
        </div>
        <Button style={ButtonStyle.White} key="ls_ads_sync_now" action={props.action} class="w-full xl:w-fit"/>
      </div>
    </div>
  )
}

export default NeedAdsSyncBanner
