import { GetNetworkQuery } from '@coris-ts/repository/query/network.query'
import { type HttpParameters } from '@coris-ts/data/parameter-type'
import { ApiEndpoint } from '../../../../shared/api-endpoint'
import type { SortDirection } from '../../../../shared/models/sort-direction'

export class GetAdsQuery extends GetNetworkQuery {
  constructor (
    private readonly shopId: number,
    private readonly from: number,
    private readonly to: number,
    private readonly offset: number,
    private readonly limit: number,
    private readonly searchTerm?: string,
    private readonly sortBy?: string,
    private readonly sortDirection?: SortDirection
  ) {
    super(ApiEndpoint.Ads)
  }

  public get headers(): HttpParameters {
    return {
      'X-Shop-Id': this.shopId
    }
  }

  public get queryParameters(): HttpParameters {
    return {
      from_date: this.from,
      to_date: this.to,
      ...this.searchTerm && { search: this.searchTerm },
      ...this.sortBy && { sort_by: this.sortBy },
      ...this.sortDirection && { sort_order: this.sortDirection },
      offset: this.offset,
      limit: this.limit
    }
  }
}
