import { type AdsSyncStatus } from '../../domain/models/ads-totals'

export class AdsTotalsEntity {
  constructor(
    public readonly missing_cogs_ads: number,
    public readonly status: AdsSyncStatus,
    public readonly last_end_sync_timestamp: number
  ) {
  }
}
